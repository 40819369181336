import React, { useRef, useEffect, ReactNode, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import useOnClickOutside from 'use-onclickoutside';

import { useClassnames, TStyle } from '#hook/use-classnames';

import style from './index.module.pcss';

export interface IProps {
    className?: string | TStyle,
    children?: ReactNode,
    onClose?(e: MouseEvent): void,
    isNoBackground?: boolean,
    isCloseButtonDisabled?: boolean
}

const Modal = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const $root = useRef<HTMLDivElement>(null);
    const $body = useRef<HTMLDivElement>(null);

    useOnClickOutside($body, (e) => {
        const path = e.composedPath();
        const $doka = document.querySelector('.doka--root');

        if(!path || !$doka || !path.includes($doka)) {
            props.onClose?.(e as unknown as MouseEvent);
        }
    });

    useEffect(() => {
        const $el = $root.current;

        if($el) {
            disableBodyScroll($el);
        }

        return () => {
            if($el) {
                enableBodyScroll($el);
            }
        };
    }, []);

    const elIconClose = () => {
        if(props.onClose && !props.isCloseButtonDisabled) {
            return (
                <svg
                    className={cn('modal__icon-close')}
                    onClick={props.onClose}
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="24"
                        cy="24"
                        r="23.5"
                        stroke="rgba(255, 255, 255, 1)"
                        strokeOpacity="0.3"
                    />
                    <path
                        d="M19.0507 28.9464L28.9502 19.0469"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M28.9493 28.9464L19.0498 19.0469"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        }
    };

    return createPortal((
        <div
            ref={$root}
            className={cn('modal')}
        >
            <div
                ref={$body}
                className={cn('modal__body', {
                    'modal__body_no-bg': props.isNoBackground
                })}
            >
                <div className={cn('modal__children')}>
                    {elIconClose()}
                    {props.children}
                </div>
            </div>
        </div>
    ), document.body);
};

export default Modal;
