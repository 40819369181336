/* eslint-disable */
// @TODO пофиксить еслинт
import * as amplitudeSource from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
import { watchAccount } from '@wagmi/core';

import { config } from '#core/wagmi';

if(import.meta.env.APP_AMPLITUDE) {
    amplitudeSource
        .init(import.meta.env.APP_AMPLITUDE)
        .promise
        .then(() => {
            watchAccount(config, {
                onChange(data) {
                    if(data.status === 'connecting') {
                        amplitude.track('wallet_connect_started');
                    } else if(data.status === 'connected') {
                        amplitude.track('wallet_connected', {
                            wallet_name: data.connector.name
                        });
                    }
                }
            });
        });
}

amplitudeSource.add(
    sessionReplayPlugin({
        sampleRate: 1
    })
);

amplitudeSource.add(autocapturePlugin());

export const amplitude = amplitudeSource;
