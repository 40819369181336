import { useMemo } from 'react';
import type { Web3Modal } from '@web3modal/wagmi';
import type { OpenOptions } from '@web3modal/scaffold/dist/types/src/client';
// import { bsc, bscTestnet, mainnet } from 'wagmi/chains';

import { config } from '#core/wagmi';

let modal!: Web3Modal;

export const useWeb3Modal = () => {
    return useMemo(() => ({
        open: async (options?: OpenOptions) => {
            if(!modal) {
                const { createWeb3Modal } = await import('@web3modal/wagmi/react');

                modal = createWeb3Modal({
                    wagmiConfig: config,
                    projectId  : import.meta.env.APP_WALLET_CONNECT_PROJECT_ID/*,
                    defaultChain: mainnet || config.chains[0]*/
                });
            }

            await modal.open(options);
        }
    }), []);
};
