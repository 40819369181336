import React from 'react';

import { IPropsSVG, SVG } from '.';

export interface IProps {
    readonly svg?: IPropsSVG
}

export const IconMenuArrow = (props: IProps) => {
    return (
        <SVG
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props.svg}
        >
            <path
                d="M22.965 2.32v19.456h-.002v1.173H3.508v-2H20.02L1.546 2.474l1.407-1.421 18.012 18.012V2.32h2Z"
            />
        </SVG>
    );
};

export default IconMenuArrow;
