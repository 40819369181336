import React from 'react';

import { IPropsSVG, SVG } from '..';

export interface IProps {
    readonly svg?: IPropsSVG
}

export const IconUserConnections = (props: IProps) => {
    return (
        <SVG
            width="48"
            height="48"
            viewBox="0 0 48 48"
            {...props.svg}
        >
            <g clipPath="url(#clip0_2487_10434)">
                <path
                    d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                    fill="white"
                />
                <path
                    d="M33.1599 28.1636C31.6255 28.1636 30.2678 28.8734 29.3506 29.9654L25.6312 27.6547C26.1817 26.553 26.4982 25.3157 26.4982 24C26.4982 22.686 26.1647 21.4586 25.6201 20.3523L29.3506 18.0347C30.2678 19.1266 31.6255 19.8365 33.1599 19.8365C35.9151 19.8365 38.1562 17.5953 38.1562 14.8402C38.1562 12.0849 35.9151 9.84375 33.1599 9.84375C30.4048 9.84375 28.1636 12.0849 28.1636 14.8401C28.1636 15.4624 28.2918 16.0522 28.5006 16.6021L24.7375 18.9399C23.2133 16.9672 20.8507 15.6728 18.1709 15.6728C13.5796 15.6728 9.84375 19.4087 9.84375 24C9.84375 26.7095 11.1645 29.0976 13.1746 30.6191V28.1635C13.1746 26.786 14.2952 25.6654 15.6727 25.6654H18.1709C16.3339 25.6654 14.8401 24.1716 14.8401 22.3345C14.8401 20.4975 16.3339 19.0036 18.1709 19.0036C20.0079 19.0036 21.5018 20.4975 21.5018 22.3345C21.5018 24.1716 20.0078 25.6654 18.1709 25.6654H20.6691C22.0466 25.6654 23.1672 26.786 23.1672 28.1635V30.6191C23.7581 30.1718 24.28 29.6422 24.7357 29.0589L28.5005 31.3979C28.2917 31.9478 28.1635 32.5376 28.1635 33.1599C28.1635 35.9151 30.4047 38.1562 33.1598 38.1562C35.9151 38.1562 38.1562 35.9151 38.1562 33.1599C38.1562 30.4048 35.9151 28.1636 33.1599 28.1636ZM18.171 20.6692C17.2529 20.6692 16.5055 21.4165 16.5055 22.3346C16.5055 23.2527 17.2529 24.0001 18.171 24.0001C19.0891 24.0001 19.8365 23.2527 19.8365 22.3346C19.8364 21.4164 19.0891 20.6692 18.171 20.6692ZM20.6692 27.3308H15.6728C15.2134 27.3308 14.8402 27.7041 14.8402 28.1635V31.6213C15.862 32.0699 16.9856 32.3272 18.171 32.3272C19.3564 32.3272 20.48 32.0699 21.5018 31.6213V28.1635C21.5018 27.7042 21.1286 27.3308 20.6692 27.3308Z"
                    fill="url(#paint0_linear_2487_10434)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_2487_10434"
                    x1="38.1562"
                    y1="24"
                    x2="9.84375"
                    y2="24"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#753CCE" />
                    <stop
                        offset="1"
                        stopColor="#410F8B"
                    />
                </linearGradient>
                <clipPath id="clip0_2487_10434">
                    <rect
                        width="48"
                        height="48"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SVG>
    );
};

export default IconUserConnections;
