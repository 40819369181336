const getItemJSON = (storage: Storage, key: string) => {
    try {
        const value = storage.getItem(key);

        if(typeof value === 'string') {
            return JSON.parse(value);
        }

        return null;
    } catch(error) {
        console.error(error);

        return null;
    }
};

const setItemJSON = (storage: Storage, key: string, value: unknown) => {
    try {
        storage.setItem(key, JSON.stringify(value));
    } catch(error) {
        console.error(error);
    }
};

export const session = {
    getItemJSON: (key: string) => getItemJSON(window.sessionStorage, key),
    setItemJSON: (key: string, value: unknown) => setItemJSON(window.sessionStorage, key, value)
};

export const local = {
    getItemJSON: (key: string) => getItemJSON(window.localStorage, key),
    setItemJSON: (key: string, value: unknown) => setItemJSON(window.localStorage, key, value),
    removeItem : (key: string) => window.localStorage.removeItem(key)
};
