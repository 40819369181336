import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Layout } from '#component/layout';
import { ErrorBoundaryRouter } from '#component/error-boundary/router';

export const COMMON: Array<RouteObject> = [
    {
        element      : <Layout />,
        ErrorBoundary: ErrorBoundaryRouter,
        children     : [
            {
                path: '/',
                lazy: () => import('./main')
            },
            {
                path: '/launchpad',
                lazy: () => import('./launchpad')
            },
            {
                path    : '/launchpad/:id',
                lazy    : () => import('./launchpad/{id}/layout'),
                children: [{
                    index: true,
                    lazy : () => import('./launchpad/{id}')
                }, {
                    path: 'buy',
                    lazy: () => import('./launchpad/{id}/buy')
                }]
            },
            {
                path: '/games',
                lazy: () => import('./games')
            },
            {
                path: '/games/:id',
                lazy: () => import('./games/{id}')
            },
            {
                path: '/games/:id/swap',
                lazy: () => import('./games/{id}/swap')
            },
            {
                path: '/for-blockchains',
                lazy: () => import('./for-blockchains')
            },
            {
                path: '/for-token-owners',
                lazy: () => import('./for-tokens')
            },
            {
                path: '/for-gamers',
                lazy: () => import('./for-gamers')
            },
            {
                path: '/for-investors',
                lazy: () => import('./for-investors')
            },
            {
                path: '/for-developers',
                lazy: () => import('./for-developers')
            },
            {
                path: '/claim/:id?',
                lazy: () => import('./claim')
            },
            {
                path: '/trade',
                lazy: () => import('./game-tokens')
            },
            {
                path: '/about',
                lazy: () => import('./about')
            },
            {
                path: '/airdrops',
                lazy: () => import('./airdrops')
            },
            {
                path: '/game/:gameId/airdrops/:airdropId',
                lazy: () => import('./airdrops/{id}')
            },
            {
                path: '/nft',
                lazy: () => import('./nft')
            },
            {
                path: '/referral',
                lazy: () => import('./referral')
            },
            {
                path: '/developers/:id',
                lazy: () => import('./developers')
            },
            {
                path: '/profile',
                lazy: () => import('./profile')
            },
            {
                path: '/news',
                lazy: () => import('./news')
            },
            {
                path: '/news/:id',
                lazy: () => import('./news/{id}')
            },
            {
                path: '/token/:id',
                lazy: () => import('./token')
            },
            {
                path: '/lp-prelaunch',
                lazy: () => import('./cpm')
            },
            {
                path: '/playweb3-stats',
                lazy: () => import('./playweb3-stats')
            },
            {
                path: '/contact-us',
                lazy: () => import('./contact-us')
            },
            {
                path: '/all-developers',
                lazy: () => import('./all-developers')
            },
            {
                path: '/terms-and-conditions/:type',
                lazy: () => import('./terms-and-conditions')
            },
            {
            },
            {
                path: '/roadmap',
                lazy: () => import('./roadmap')
            },
            {
                path: '/tasks',
                lazy: () => import('./tasks')
            },
            {
                path: '*',
                lazy: () => import('./404')
            }
        ]
    }
];
