import sourceNumeral from 'numeral';

// import i18n from '#locale';

// sourceNumeral.register('locale', 'en', {
//     delimiters: {
//         thousands: ' ',
//         decimal  : ','
//     },
//     abbreviations: {
//         thousand: 'thnd.',
//         million : 'mln.',
//         billion : 'bln.',
//         trillion: 'trln.'
//     },
//     ordinal() {
//         return '.';
//     },
//     currency: {
//         symbol: 'руб.'
//     }
// });

sourceNumeral.locale('en');

// i18n.on('languageChanged', () => {
//     sourceNumeral.locale('en');
// });

export const numeral = sourceNumeral;
