import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import { useClassnames } from '#hook/use-classnames';

import { Button } from '#component/button';

import style from './index.module.pcss';

const COOKIE_NAME = 'cookie-agreement';

const cookie = Cookies.get(COOKIE_NAME);

export const CookieAgreement = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation(undefined, {
        keyPrefix: 'components.cookie-agreement'
    });
    const [agreed, setAgreed] = useState(!!cookie);

    if(agreed) {
        return null;
    }

    return (
        <div className={cn('cookie-agreement')}>
            <div className={cn('cookie-agreement__content')}>
                <article>🍪</article>
                <Trans
                    i18nKey="components.cookie-agreement.text"
                    parent="p"
                    components={{
                        element: <Link
                            className={cn('cookie-agreement__link')}
                            to="/terms-and-conditions/cookie-policy"
                            target="_blank"
                            rel="noreferrer"
                        />
                    }}
                />
            </div>
            <Button
                className={cn('cookie-agreement__button')}
                children={t('button')}
                uppercase={true}
                presetStyle="primary-violet"
                onClick={() => {
                    Cookies.set(COOKIE_NAME, 'true');
                    setAgreed(true);
                }}
            />
        </div>
    );
};
