import React from 'react';

import { IPropsSVG, SVG } from '..';

export interface IProps {
    readonly svg?: IPropsSVG
}

export const IconUserAdd = (props: IProps) => {
    return (
        <SVG
            width="48"
            height="48"
            viewBox="0 0 48 48"
            {...props.svg}
        >
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill="white"
            />
            <path
                d="M15.097 16.4795C15.097 12.8147 18.068 9.84375 21.7327 9.84375C25.3975 9.84375 28.3685 12.8147 28.3685 16.4795C28.3685 20.1443 25.3975 23.1152 21.7327 23.1152C18.068 23.1152 15.097 20.1443 15.097 16.4795ZM38.1562 32.6265C38.1562 35.6805 35.6805 38.1562 32.6265 38.1562C29.5725 38.1562 27.0968 35.6805 27.0968 32.6265C27.0968 29.5725 29.5725 27.0968 32.6265 27.0968C35.6805 27.0968 38.1562 29.5724 38.1562 32.6265ZM35.6678 32.6265C35.6678 32.1684 35.2965 31.797 34.8383 31.797H33.4559V30.4146C33.4559 29.9565 33.0846 29.5851 32.6264 29.5851C32.1683 29.5851 31.7969 29.9564 31.7969 30.4146V31.797H30.4145C29.9564 31.797 29.585 32.1683 29.585 32.6265C29.585 33.0847 29.9563 33.456 30.4145 33.456H31.7969V34.8384C31.7969 35.2965 32.1683 35.6679 32.6264 35.6679C33.0846 35.6679 33.4559 35.2966 33.4559 34.8384V33.456H34.8383C35.2965 33.4559 35.6678 33.0846 35.6678 32.6265ZM25.4377 32.6265C25.4377 29.5825 27.3396 26.9746 30.0173 25.928C28.9396 24.878 27.6594 24.0279 26.2326 23.4443C24.9355 24.2852 23.3902 24.7742 21.7327 24.7742C20.0752 24.7742 18.5298 24.2851 17.2327 23.4442C12.8428 25.2394 9.84375 29.5593 9.84375 34.4513V37.3268C9.84375 37.7848 10.2151 38.1562 10.6732 38.1562H28.0383C26.4504 36.8365 25.4377 34.8476 25.4377 32.6265Z"
                fill="url(#paint0_linear_2487_10424)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2487_10424"
                    x1="38.1562"
                    y1="24"
                    x2="9.84375"
                    y2="24"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#753CCE" />
                    <stop
                        offset="1"
                        stopColor="#410F8B"
                    />
                </linearGradient>
            </defs>
        </SVG>
    );
};

export default IconUserAdd;
