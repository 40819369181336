interface IImage {
    id?: number | null,
    kind?: string | null,
    resolution?: string | null,
    url: string,
    created_at?: string | null
}

export const getImageByTypeAndSize = (images: Array<IImage | null>, type: string, size: { w: number, h: number } = { w: 100, h: 100 }) => {
    const image = images?.find((imageItem) => {
        if(imageItem) {
            return imageItem.kind === type;
        }

        return null;
    });

    return image && `${image.url}?w=${size.w}&h=${size.h}&format=webp`;
};
