export default [{
    'inputs': [{
        'internalType': 'string',
        'name'        : '_nameArg',
        'type'        : 'string'
    }, { 'internalType': 'address', 'name': '_tokenArg', 'type': 'address' }, {
        'internalType': 'address',
        'name'        : '_vaultArg',
        'type'        : 'address'
    }],
    'stateMutability': 'nonpayable',
    'type'           : 'constructor'
}, { 'inputs': [], 'name': 'AccessControlBadConfirmation', 'type': 'error' }, {
    'inputs': [{
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }, { 'internalType': 'bytes32', 'name': 'neededRole', 'type': 'bytes32' }],
    'name': 'AccessControlUnauthorizedAccount',
    'type': 'error'
}, { 'inputs': [], 'name': 'AccessDenied', 'type': 'error' }, {
    'inputs': [],
    'name'  : 'AlreadyPaid',
    'type'  : 'error'
}, { 'inputs': [], 'name': 'AlreadyRegistered', 'type': 'error' }, {
    'inputs': [],
    'name'  : 'EmptyPrice',
    'type'  : 'error'
}, { 'inputs': [], 'name': 'InActivePeriod', 'type': 'error' }, {
    'inputs': [],
    'name'  : 'InvalidAmountToPurchase',
    'type'  : 'error'
}, { 'inputs': [], 'name': 'InvalidOnSale', 'type': 'error' }, {
    'inputs': [],
    'name'  : 'InvestmentNotRegistered',
    'type'  : 'error'
}, { 'inputs': [], 'name': 'NotAllowedAtCurrentStage', 'type': 'error' }, {
    'inputs': [],
    'name'  : 'NotConfigured',
    'type'  : 'error'
}, { 'inputs': [], 'name': 'NotFound', 'type': 'error' }, {
    'inputs': [],
    'name'  : 'OnSaleExceeded',
    'type'  : 'error'
}, { 'inputs': [], 'name': 'ReentrancyGuardReentrantCall', 'type': 'error' }, {
    'inputs': [],
    'name'  : 'RoundSuspended',
    'type'  : 'error'
}, {
    'anonymous': false,
    'inputs'   : [{
        'indexed'     : true,
        'internalType': 'address',
        'name'        : '_beneficiary',
        'type'        : 'address'
    }, { 'indexed': true, 'internalType': 'uint256', 'name': '_tokens', 'type': 'uint256' }, {
        'indexed'     : false,
        'internalType': 'uint256',
        'name'        : '_chain',
        'type'        : 'uint256'
    }, { 'indexed': false, 'internalType': 'uint256', 'name': '_payment', 'type': 'uint256' }],
    'name': 'ActiveSalePurchase',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{
        'indexed'     : true,
        'internalType': 'address',
        'name'        : '_beneficiary',
        'type'        : 'address'
    }, { 'indexed': true, 'internalType': 'uint256', 'name': '_tokens', 'type': 'uint256' }, {
        'indexed'     : false,
        'internalType': 'uint256',
        'name'        : '_payment',
        'type'        : 'uint256'
    }],
    'name': 'PreSalePurchase',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{
        'indexed'     : true,
        'internalType': 'address',
        'name'        : '_beneficiary',
        'type'        : 'address'
    }, { 'indexed': true, 'internalType': 'uint256', 'name': '_payment', 'type': 'uint256' }],
    'name': 'Refund',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'indexed'     : true,
        'internalType': 'bytes32',
        'name'        : 'previousAdminRole',
        'type'        : 'bytes32'
    }, { 'indexed': true, 'internalType': 'bytes32', 'name': 'newAdminRole', 'type': 'bytes32' }],
    'name': 'RoleAdminChanged',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'indexed'     : true,
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }, { 'indexed': true, 'internalType': 'address', 'name': 'sender', 'type': 'address' }],
    'name': 'RoleGranted',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'indexed'     : true,
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }, { 'indexed': true, 'internalType': 'address', 'name': 'sender', 'type': 'address' }],
    'name': 'RoleRevoked',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'uint256', 'name': '_id', 'type': 'uint256' }],
    'name'     : 'RoundCompleted',
    'type'     : 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'uint256', 'name': '_id', 'type': 'uint256' }],
    'name'     : 'RoundFailed',
    'type'     : 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'uint256', 'name': '_id', 'type': 'uint256' }],
    'name'     : 'RoundRegistered',
    'type'     : 'event'
}, {
    'inputs'         : [],
    'name'           : 'ACCOUNTANT_ROLE',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'AFFILIATES_GROUP',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'BURN_ROLE',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'DEFAULT_ADMIN_ROLE',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'INVESTORS_GROUP',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'MANAGER_ROLE',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs' : [{ 'internalType': 'uint256', 'name': '_targetRound', 'type': 'uint256' }],
    'name'   : 'activeSaleOnChainsOf',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'chain',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'tokens', 'type': 'uint256' }],
        'internalType': 'struct IIGO.ChainPartitionView[]',
        'name'        : '',
        'type'        : 'tuple[]'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'uint256', 'name': '_partition', 'type': 'uint256' }, {
        'internalType': 'address',
        'name'        : '_account',
        'type'        : 'address'
    }],
    'name'   : 'affiliateOf',
    'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : '',
        'type'        : 'uint256'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'uint256', 'name': '_tokenAmount', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : '_chain',
        'type'        : 'uint256'
    }],
    'name'           : 'buyOnActiveSale',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'uint256', 'name': '_tokenAmount', 'type': 'uint256' }],
    'name'           : 'buyOnPreSale',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs' : [],
    'name'   : 'chains',
    'outputs': [{
        'components': [{ 'internalType': 'uint256', 'name': 'id', 'type': 'uint256' }, {
            'internalType': 'string',
            'name'        : 'name',
            'type'        : 'string'
        }, { 'internalType': 'uint256', 'name': 'date', 'type': 'uint256' }],
        'internalType': 'struct IIGO.Chain[]',
        'name'        : '',
        'type'        : 'tuple[]'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'completeRound',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'address', 'name': '_account', 'type': 'address' }, {
        'internalType': 'bytes',
        'name'        : '_params',
        'type'        : 'bytes'
    }],
    'name'   : 'contributeOf',
    'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : '',
        'type'        : 'uint256'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'getAcceptedToken',
    'outputs'        : [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs' : [],
    'name'   : 'getActiveSale',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'date',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'start', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'end',
            'type'        : 'uint256'
        }, {
            'components': [{ 'internalType': 'uint256', 'name': 'id', 'type': 'uint256' }, {
                'internalType': 'string',
                'name'        : 'name',
                'type'        : 'string'
            }],
            'internalType': 'struct IIGO.ChainView[]',
            'name'        : 'chains',
            'type'        : 'tuple[]'
        }, { 'internalType': 'uint256[]', 'name': 'targetAllowances', 'type': 'uint256[]' }, {
            'internalType': 'uint256[]',
            'name'        : 'allowances',
            'type'        : 'uint256[]'
        }, { 'internalType': 'uint256', 'name': 'price', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'onSale',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'fundsRaised', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'sold',
            'type'        : 'uint256'
        }],
        'internalType': 'struct IIGO.SaleView',
        'name'        : '',
        'type'        : 'tuple'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs' : [{ 'internalType': 'uint256', 'name': '_targetRound', 'type': 'uint256' }],
    'name'   : 'getActiveSaleOf',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'date',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'start', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'end',
            'type'        : 'uint256'
        }, {
            'components': [{ 'internalType': 'uint256', 'name': 'id', 'type': 'uint256' }, {
                'internalType': 'string',
                'name'        : 'name',
                'type'        : 'string'
            }],
            'internalType': 'struct IIGO.ChainView[]',
            'name'        : 'chains',
            'type'        : 'tuple[]'
        }, { 'internalType': 'uint256[]', 'name': 'targetAllowances', 'type': 'uint256[]' }, {
            'internalType': 'uint256[]',
            'name'        : 'allowances',
            'type'        : 'uint256[]'
        }, { 'internalType': 'uint256', 'name': 'price', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'onSale',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'fundsRaised', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'sold',
            'type'        : 'uint256'
        }],
        'internalType': 'struct IIGO.SaleView',
        'name'        : '',
        'type'        : 'tuple'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'getName',
    'outputs'        : [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs' : [],
    'name'   : 'getPreSale',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'date',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'start', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'end',
            'type'        : 'uint256'
        }, {
            'components': [{ 'internalType': 'uint256', 'name': 'id', 'type': 'uint256' }, {
                'internalType': 'string',
                'name'        : 'name',
                'type'        : 'string'
            }],
            'internalType': 'struct IIGO.ChainView[]',
            'name'        : 'chains',
            'type'        : 'tuple[]'
        }, { 'internalType': 'uint256[]', 'name': 'targetAllowances', 'type': 'uint256[]' }, {
            'internalType': 'uint256[]',
            'name'        : 'allowances',
            'type'        : 'uint256[]'
        }, { 'internalType': 'uint256', 'name': 'price', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'onSale',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'fundsRaised', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'sold',
            'type'        : 'uint256'
        }],
        'internalType': 'struct IIGO.SaleView',
        'name'        : '',
        'type'        : 'tuple'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs' : [{ 'internalType': 'uint256', 'name': '_targetRound', 'type': 'uint256' }],
    'name'   : 'getPreSaleOf',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'date',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'start', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'end',
            'type'        : 'uint256'
        }, {
            'components': [{ 'internalType': 'uint256', 'name': 'id', 'type': 'uint256' }, {
                'internalType': 'string',
                'name'        : 'name',
                'type'        : 'string'
            }],
            'internalType': 'struct IIGO.ChainView[]',
            'name'        : 'chains',
            'type'        : 'tuple[]'
        }, { 'internalType': 'uint256[]', 'name': 'targetAllowances', 'type': 'uint256[]' }, {
            'internalType': 'uint256[]',
            'name'        : 'allowances',
            'type'        : 'uint256[]'
        }, { 'internalType': 'uint256', 'name': 'price', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'onSale',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'fundsRaised', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'sold',
            'type'        : 'uint256'
        }],
        'internalType': 'struct IIGO.SaleView',
        'name'        : '',
        'type'        : 'tuple'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }],
    'name'           : 'getRoleAdmin',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs' : [],
    'name'   : 'getRound',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'id',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'date', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'releasedAt',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'totalSupply', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'minimumRaised',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'fundsRaised', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'sold',
            'type'        : 'uint256'
        }, { 'internalType': 'enum IIGO.RoundStep', 'name': 'step', 'type': 'uint8' }, {
            'internalType': 'bool',
            'name'        : 'suspended',
            'type'        : 'bool'
        }],
        'internalType': 'struct IIGO.RoundView',
        'name'        : '',
        'type'        : 'tuple'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'getRoundId',
    'outputs'        : [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs' : [{ 'internalType': 'uint256', 'name': '_targetRound', 'type': 'uint256' }],
    'name'   : 'getRoundOf',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'id',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'date', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'releasedAt',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'totalSupply', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'minimumRaised',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'fundsRaised', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'sold',
            'type'        : 'uint256'
        }, { 'internalType': 'enum IIGO.RoundStep', 'name': 'step', 'type': 'uint8' }, {
            'internalType': 'bool',
            'name'        : 'suspended',
            'type'        : 'bool'
        }],
        'internalType': 'struct IIGO.RoundView',
        'name'        : '',
        'type'        : 'tuple'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'getVault',
    'outputs'        : [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }],
    'name'           : 'grantRole',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }],
    'name'           : 'hasRole',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'uint256', 'name': '_targetRound', 'type': 'uint256' }, {
        'internalType': 'address',
        'name'        : '_beneficiary',
        'type'        : 'address'
    }],
    'name'   : 'investmentOf',
    'outputs': [{
        'components': [{
            'internalType': 'uint256',
            'name'        : 'investedFunds',
            'type'        : 'uint256'
        }, { 'internalType': 'uint256', 'name': 'refundedFunds', 'type': 'uint256' }, {
            'internalType': 'uint256',
            'name'        : 'tokens',
            'type'        : 'uint256'
        }],
        'internalType': 'struct IIGO.RoundInvestment',
        'name'        : '',
        'type'        : 'tuple'
    }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'isAutoReleasePayouts',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'uint256', 'name': '_onSale', 'type': 'uint256' }],
    'name'           : 'launchActiveSale',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'launchPreSale',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bool', 'name': '_pause', 'type': 'bool' }],
    'name'           : 'pauseRound',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'refund',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'uint256', 'name': '_id', 'type': 'uint256' }, {
        'internalType': 'string',
        'name'        : '_chain',
        'type'        : 'string'
    }],
    'name'           : 'registerChain',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'internalType': 'address',
        'name'        : 'callerConfirmation',
        'type'        : 'address'
    }],
    'name'           : 'renounceRole',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }],
    'name'           : 'revokeRole',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [],
    'name'           : 'sendFunds',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bool', 'name': '_value', 'type': 'bool' }],
    'name'           : 'setAutoReleasePayouts',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'uint256', 'name': '_start', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : '_end',
        'type'        : 'uint256'
    }, { 'internalType': 'uint256', 'name': '_price', 'type': 'uint256' }, {
        'internalType': 'uint256[]',
        'name'        : '_targetChains',
        'type'        : 'uint256[]'
    }],
    'name'           : 'setupActiveSale',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'uint256', 'name': '_start', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : '_end',
        'type'        : 'uint256'
    }, { 'internalType': 'uint256', 'name': '_onSale', 'type': 'uint256' }, {
        'internalType': 'uint256[]',
        'name'        : '_allowances',
        'type'        : 'uint256[]'
    }, { 'internalType': 'uint256', 'name': '_price', 'type': 'uint256' }],
    'name'           : 'setupPreSale',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'uint256', 'name': '_totalSupply', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : '_minimumRaised',
        'type'        : 'uint256'
    }, {
        'components': [{ 'internalType': 'string', 'name': 'name', 'type': 'string' }, {
            'internalType': 'address',
            'name'        : 'account',
            'type'        : 'address'
        }, { 'internalType': 'uint256', 'name': 'share', 'type': 'uint256' }],
        'internalType': 'struct ISplitter.Payee[]',
        'name'        : '_payeesArg',
        'type'        : 'tuple[]'
    }],
    'name'           : 'setupRound',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bytes4', 'name': 'interfaceId', 'type': 'bytes4' }],
    'name'           : 'supportsInterface',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'uint256', 'name': '_amount', 'type': 'uint256' }],
    'name'           : 'withdraw',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}] as const;
