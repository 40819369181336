import React, { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { local } from '#adapter/storage';
import { useClassnames } from '#hook/use-classnames';

import { ErrorStack } from '#component/error-stack';

import style from './index.module.pcss';

export const ErrorBoundaryRouter = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const error = useRouteError();

    useEffect(() => {
        if(
            error instanceof Error
            && error.message.startsWith('Failed to fetch dynamically imported module:')
        ) {
            const value = local.getItemJSON('chunk_failed');

            if(!value || new Date().getTime() > value) {
                local.setItemJSON('chunk_failed', new Date().getTime() + 10000);

                window.location.reload();
            }
        }
    }, [error]);

    return (
        <div className={cn('error-boundary-router')}>
            <h1>{t('components.error-boundary.title')}</h1>
            <ErrorStack error={error} />
            <button
                type="button"
                children={t('components.error-boundary.buttons.main')}
                onClick={(e) => {
                    e.preventDefault();

                    navigate('/');
                }}
            />
        </div>
    );
};
