import React from 'react';

import { useClassnames, type TStyle } from '#hook/use-classnames';

import style from './index.module.pcss';

export interface IProps {
    error?: unknown,
    className?: string | TStyle
}

export const ErrorStack = (props: IProps) => {
    const cn = useClassnames(style, props.className);

    if(props.error instanceof Error && props.error?.stack) {
        return (
            <pre
                className={cn('error-stack')}
                children={props.error.stack}
            />
        );
    }

    return null;
};
