import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { differenceInHours, closestTo, isAfter, isEqual } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { type TStyle, useClassnames } from '#hook/use-classnames';
import { useWindowResize } from '#hook/use-window-resize';
import { useDispatch, useSelector } from '#core/store';
import { apiInject } from '#adapter/api/main/inject';
import { TCode200 } from '#adapter/api/main/api/v1/igos/get';
import { getImageByTypeAndSize } from '#helper/get-image';

import Modal from '#component/modal';
import { slice as sliceMediaQuery } from '#component/media-query/slice';
import { GradientText } from '#component/gradient-text';
import { Countdown, IProps as ICountdownProps } from '#component/countdown';
import { Button } from '#component/button';

import { slice } from '../../slice';
import giftImage from './image/gift.png';

import style from './index.module.pcss';

export interface IProps {
    className?: TStyle | string
}

let COUNT_START = 0;

const BANNER_MAX_HEIGHT = 500;

type TIgo = TCode200[number];

export const IgoBanner = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const COOKIE_NAME_SHOW_COUNT = 'cookie-igo-banner-shown';
    const COOKIE_NAME_SHOW_TIME = 'cookie-igo-banner-shown-time';
    const COOKIE_NAME_BANNER_ID = 'cookie-igo-banner-id';
    const cookieShowCount = Cookies.get(COOKIE_NAME_SHOW_COUNT);
    const cookieShowTime = Cookies.get(COOKIE_NAME_SHOW_TIME);
    const cookieIgoId = Cookies.get(COOKIE_NAME_BANNER_ID);
    const parsedShowCount = cookieShowCount ? parseInt(cookieShowCount, 10) : 0;
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);
    const open = useSelector((state) => state[slice.name].opened);

    const [currentIgo, setCurrentIgo] = useState<TIgo>();

    const { data, isLoading } = apiInject.endpoints.getListIGO.useQuery({
        query: {
            status: 'announce'
        }
    });

    useEffect(() => {
        if(data) {
            const datesArr = data.reduce((acc, curr) => {
                if(curr.start_date && (isAfter(new Date(curr.start_date), new Date()))) {
                    acc.push(curr.start_date);
                }

                return acc;
            }, [] as Array<string>);
            const closestDate = closestTo(new Date(), datesArr || []);

            const payload = data.find((item) => {
                return (item.start_date && closestDate) && isEqual(item.start_date, closestDate);
            });

            setCurrentIgo(payload);
        }
    }, [data]);

    useEffect(() => {
        const differenceTime = cookieShowTime ? differenceInHours(new Date(), new Date(cookieShowTime)) : 0;

        if(parsedShowCount > 2 || (parsedShowCount > 0 && differenceTime < 24)) {
            dispatch(slice.actions.set(false));
        } else {
            dispatch(slice.actions.set(true));
        }
    }, [dispatch, cookieShowTime, cookieShowCount, parsedShowCount]);

    useEffect(() => {
        if(currentIgo && cookieIgoId && (currentIgo.id !== parseInt(cookieIgoId, 10))) {
            Cookies.remove(COOKIE_NAME_SHOW_COUNT);
            Cookies.remove(COOKIE_NAME_SHOW_TIME);
            Cookies.remove(COOKIE_NAME_SHOW_COUNT);

            dispatch(slice.actions.set(true));
        }
    }, [currentIgo, cookieIgoId]);

    const [width, height] = useWindowResize();

    const onClickClose = () => {
        const incrementCount = parsedShowCount ? parsedShowCount + 1 : COUNT_START + 1;

        Cookies.set(COOKIE_NAME_SHOW_COUNT, String(incrementCount));
        Cookies.set(COOKIE_NAME_SHOW_TIME, new Date().toISOString());

        if(currentIgo) {
            Cookies.set(COOKIE_NAME_BANNER_ID, String(currentIgo.id));
        }

        COUNT_START = incrementCount;

        dispatch(slice.actions.set(false));
    };

    const elIconClose = () => {
        return (
            <div className={cn('marketing-banner__icon-close-container')}>
                <svg
                    viewBox="0 0 75 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={cn('marketing-banner__icon-close', {
                        'marketing-banner__icon-close_height': height - 160 < BANNER_MAX_HEIGHT
                    })}
                    onClick={onClickClose}
                >
                    <line
                        x1="5.76777"
                        y1="2.40801"
                        x2="72.9429"
                        y2="69.5832"
                        stroke="white"
                        strokeWidth="5"
                    />
                    <line
                        x1="2.23223"
                        y1="69.408"
                        x2="69.4074"
                        y2="2.23287"
                        stroke="white"
                        strokeWidth="5"
                    />
                </svg>
            </div>
        );
    };

    if(!open || !currentIgo || isLoading) {
        return null;
    }

    const elContent = () => {
        const imageUrl = getImageByTypeAndSize(currentIgo.images || [], 'background', { w: 880, h: 500 });

        return (
            <div
                style={{
                    maxWidth       : `${width * (isDesktop ? 0.7 : 1)}px`,
                    height         : isDesktop ? `${height * 0.8}px` : undefined,
                    maxHeight      : isDesktop ? `${BANNER_MAX_HEIGHT}px` : undefined,
                    backgroundImage: `url(${imageUrl})`
                }}
                className={cn('marketing-banner__content')}
            >
                {elIconClose()}
                <img
                    src={giftImage}
                    alt="gift"
                    className={cn('marketing-banner__gift')}
                />
                <div
                    className={cn('marketing-banner__content-inner')}
                >
                    <GradientText
                        // @ts-expect-error TODO
                        children={currentIgo.title}
                        className={cn('marketing-banner__content-gradient')}
                    />
                    {(currentIgo.start_date && currentIgo.status) && (
                        <Countdown
                            className={cn('marketing-banner__countdown')}
                            date={new Date(currentIgo.start_date)}
                            status={currentIgo.status as ICountdownProps['status']}
                        />
                    )}
                    <div className={cn('marketing-banner__content-buttons')}>
                        <Button
                            presetStyle="secondary-orange"
                            uppercase={true}
                            onClick={(e) => {
                                e.preventDefault();

                                navigate(`/launchpad/${currentIgo.id}`);

                                onClickClose();
                            }}
                        >
                            {t('components.banner.marketing.igo.details')}
                        </Button>
                        <Button
                            presetStyle="primary-violet"
                            uppercase={true}
                            onClick={(e) => {
                                e.preventDefault();

                                navigate(`/launchpad/${currentIgo.id}/buy`);

                                onClickClose();
                            }}
                        >
                            {t('components.banner.marketing.igo.invest')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            onClose={onClickClose}
            isNoBackground={true}
            isCloseButtonDisabled={true}
            className={{
                'modal'          : cn('marketing-banner__modal'),
                'modal__children': cn('marketing-banner'),
                'modal__body'    : cn('marketing-banner__modal-body')
            }}
        >
            {elContent()}
        </Modal>
    );
};
