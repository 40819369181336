export const FOOTER_LINKS = [
    'profile',
    'claim',
    'trade',
    'launchpad',
    'games',
    'tasks',
    'for-developers',
    'for-investors',
    'for-gamers',
    'for-blockchains',
    'for-token-owners',
    'referral',
    'about',
    'playweb3-stats'
] as const;

export const MENU_LINKS = [
    'profile',
    'claim',
    'games',
    'trade',
    'launchpad',
    'tasks'
] as const;

export const DESKTOP_NAV_LINKS = [
    'games',
    'trade',
    'launchpad',
    'tasks'
] as const;

// %COIN% - BUSD
export const DEX_PAIR_ADDRESSES = [
    '0x7E2252f11335E3C8F0605010a0207E6d66CB61eF', // XLA
    '0xa0f19146914e3C160897059ef8695BcD9fcf98b2', // DOT
    '0x804678fa97d91B974ec2af3c843270886528a9E6', // CAKE
    '0x1Efcb446bFa553A2EB2fff99c9F76962be6ECAC3', // DOGE
    '0x6c76e2d495e7E13Cf6425922D5580F40F75e4aB1', // ADA
    '0x118E37AFf646d58707D553F39293FEFfD4bE1A2B', // NETMIND
    '0x21C21F2c8Dc4ea09aAEeA4C0d2C1B7e1eC2e63e2', // BINARY
    '0xf2688Fb5B81049DFB7703aDa5e770543770612C4', // WBNB,
    '0xBCEA09e9e882eC2Bb6dCE07c4e6669968846CaBD', // STG
    '0xf15A72B15fC4CAeD6FaDB1ba7347f6CCD1E0Aede' // TRUNK
];

export const STATUS_MAP = {
    0: 'upcoming',
    1: 'presale',
    2: 'active',
    3: 'completed',
    4: 'refund'
} as const;

export const PW3_TOKEN_ADDRESS = '0x81f1507fA23629684AEcA614723aD831966de8dD';
export const PW3_VESTING_TOKEN_ADDRESS = '0x474bAC9E4c04415BdE2e128FE9b89406420ad986';
