import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
    opened: boolean
}

const initialState: IState = {
    opened: false
};

export const slice = createSlice({
    initialState,
    name    : 'menu',
    reducers: {
        reset: () => initialState,
        set  : (state, action: PayloadAction<boolean>) => {
            return {
                opened: action.payload
            };
        }
    }
});
