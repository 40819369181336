import React, { Fragment, useEffect, useRef, useState, MouseEvent } from 'react';

import { Button, IProps as IButtonProps } from '#component/button';
import IconOk from '#component/icons/ok';

export interface IProps {
    readonly button: IButtonProps,
    readonly copiedText: string,
    readonly linkToCopy: string
}

type TTimer = ReturnType<typeof setTimeout>;

const SECONDS_COUNT = 5;

export const CopyButton = (props: IProps) => {
    const [counter, setCounter] = useState(SECONDS_COUNT);
    const [copied, setCopied] = useState(false);

    const intervalRef = useRef<TTimer>();

    useEffect(() => {
        if(copied) {
            intervalRef.current = setInterval(() => setCounter((prev) => prev - 1), 1000);

            if(counter <= 0) {
                clearInterval(intervalRef.current);
                setCopied(false);
                setCounter(SECONDS_COUNT);
            }
        }

        return () => clearInterval(intervalRef.current);
    }, [counter, copied]);

    const onClickCopy = (e: MouseEvent<HTMLElement>) => {
        setCopied(true);
        void navigator.clipboard.writeText(props.linkToCopy);
        props.button.onClick?.(e);
    };

    const elContent = () => {
        if(copied && counter > 0) {
            return (
                <Fragment>
                    <IconOk />
                    {props.copiedText}
                </Fragment>
            );
        }

        return props.button.children;
    };

    return (
        <Button
            {...props.button}
            onClick={onClickCopy}
            children={elContent()}
        />
    );
};
