import { useEffect, type ReactNode } from 'react';

import { useDispatch } from '#core/store';

import { MEDIA_MATCHES, slice } from './slice';

interface IProps {
    children?: ReactNode
}

export const MediaQuery = (props: IProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const onUpdateMediaQuery = (e: MediaQueryListEvent) => {
            dispatch(
                slice.actions.set({
                    isDesktop: e.matches,
                    isMobile : !e.matches
                })
            );
        };

        MEDIA_MATCHES.isDesktop.addEventListener('change', onUpdateMediaQuery);

        return () => {
            MEDIA_MATCHES.isDesktop.removeEventListener('change', onUpdateMediaQuery);
        };
    }, []);

    return props.children;
};
