import i18n, { type BackendModule, type CallbackError, ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { store } from '#core/store';
import api from '#adapter/api/main';

export const SUPPORTED_LNGS = ['ru', 'en', 'fr', 'es', 'pt', 'tr', 'zh', 'id', 'ko', 'ja', 'ar'] as const;

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use({
        type: 'backend',
        read(language, namespace, callback) {
            let promise!: Promise<{ default: ResourceKey }>;

            if(namespace.includes('/')) {
                const ns = namespace.split('/');

                if(ns.length === 2) {
                    promise = import(`./../${ns[0]}/${ns[1]}/locales/${language}.json`);
                }
            } else {
                promise = import(`./${language}/${namespace}.json`);
            }

            promise
                .then((resources) => {
                    callback(null, resources.default);
                })
                .catch((error: CallbackError) => {
                    callback(error, null);
                });
        }
    } as BackendModule)
    .init({
        debug        : import.meta.env.DEV,
        lowerCaseLng : true,
        fallbackLng  : 'en',
        returnNull   : false,
        supportedLngs: SUPPORTED_LNGS,
        ns           : 'translation',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order : ['cookie'],
            caches: ['cookie']
        }
    })
    .catch(console.error);

i18n.on('languageChanged', () => {
    store.dispatch(api.util.resetApiState());
});

export default i18n;
