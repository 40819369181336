import { http, createConfig } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';
import { injected, walletConnect } from 'wagmi/connectors';

import { local } from '#adapter/storage';

export const chain = import.meta.env.APP_IS_PROD === 'true' ? bsc : bscTestnet;

const WAGMI_STORE_LS_KEY = 'wagmi.store';
const lsWagmiStore = local.getItemJSON(WAGMI_STORE_LS_KEY);

if(lsWagmiStore?.state.chainId && lsWagmiStore?.state.chainId !== chain.id) {
    local.removeItem(WAGMI_STORE_LS_KEY);
}

export const config = createConfig({
    chains    : [chain],
    // @ts-expect-error Динамический чейн
    transports: {
        [chain.id]: http()
    },
    connectors: [
        injected({ shimDisconnect: true }),
        walletConnect({
            isNewChainsStale: true,
            projectId       : import.meta.env.APP_WALLET_CONNECT_PROJECT_ID,
            showQrModal     : false,
            metadata        : {
                name       : 'Playweb3 Platform',
                description: 'Playweb3 Platform',
                url        : 'https://web3modal.com',
                icons      : ['https://avatars.githubusercontent.com/u/37784886']
            }
        })
    ]
});

export default config;
