import { useRef, useEffect, DependencyList, EffectCallback } from 'react';

export const useDidUpdateEffect = (effect: EffectCallback, deps: DependencyList) => {
    const init = useRef(false);

    useEffect(() => {
        if(init.current) {
            effect();
        } else {
            init.current = true;
        }
    }, deps);
};
