// @TODO Возможно, стоит сделать автогенерацию
import { IconUsersConnected } from './users-connected';
import { IconUserAdd } from './user-add';
import { IconUserConnections } from './user-connections';
import { IconUserChained } from './user-chained';

export default {
    'user-connection': IconUserConnections,
    'user-add'       : IconUserAdd,
    'users-connected': IconUsersConnected,
    'user-chained'   : IconUserChained
};
