import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { COMMON } from '#route/config';

const ROUTES = createBrowserRouter(COMMON, {
    basename: '/'
});

export const Routes = () => {
    return (
        <RouterProvider
            router={ROUTES}
        />
    );
};
