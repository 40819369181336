import { ReactNode, createElement, ReactHTML } from 'react';

import { type TStyle, useClassnames } from '#hook/use-classnames';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string,
    readonly children?: ReactNode,
    readonly tagName?: keyof ReactHTML
}

export const GradientText = ({ tagName = 'span', ...props }: IProps) => {
    const cn = useClassnames(style, props.className);

    return createElement(tagName, {
        className: cn('gradient-text'),
        children : props.children
    });
};
