import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { type TStyle, useClassnames } from '#hook/use-classnames';

import { Logo } from '#component/logo';
import { SocialBar } from '#component/social-bar';
import { LangControls } from '#component/lang-controls';
import { MarketcapOverview } from '#component/marketcap-overview';

import { FOOTER_LINKS } from '#const';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string
}

export const Footer = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();

    return (
        <footer className={cn('footer')}>
            <Logo className={cn('footer__logo')} />
            <SocialBar
                isWithDescription={false}
                className={cn('footer__social-bar')}
            />
            <LangControls
                preset="footer"
                className={cn('footer__lang-controls')}
            />
            <MarketcapOverview className={cn('footer__tokens')} />
            <ul className={cn('footer__links')}>
                {FOOTER_LINKS.map((link) => (
                    <li
                        key={link}
                        className={cn('footer__links-item')}
                    >
                        <Link
                            to={`/${link}`}
                            className={cn('footer__link')}
                            title={t(`components.footer.links.${link}`)}
                            children={t(`components.footer.links.${link}`)}
                        />
                    </li>
                ))}
            </ul>
            <div className={cn('footer__copyright')}>
                <span
                    children={t('components.footer.copyright', {
                        year: new Date().getFullYear()
                    })}
                />
                <Link
                    to="/contact-us"
                    // className={cn('footer__link')}
                    title={t('components.footer.contact-us')}
                    children={t('components.footer.contact-us')}
                />
                <a
                    href={`mailto:${t('components.footer.email')}`}
                    title={t('components.footer.email')}
                    children={t('components.footer.email')}
                />
                <Link
                    to="/terms-and-conditions/pw3"
                    title={t('components.footer.terms-and-conditions')}
                    children={t('components.footer.terms-and-conditions')}
                    target="_blank"
                    rel="noreferrer"
                />
                <Link
                    to="/terms-and-conditions/privacy-policy"
                    title={t('components.footer.privacy-policy')}
                    children={t('components.footer.privacy-policy')}
                    target="_blank"
                    rel="noreferrer"
                />
            </div>
        </footer>
    );
};
