import React from 'react';

import { IPropsSVG, SVG } from '.';

export interface IProps {
    readonly svg?: IPropsSVG
}

export const IconOk = (props: IProps) => {
    return (
        <SVG
            width="14"
            height="14"
            viewBox="0 0 14 14"
            {...props.svg}
        >
            <g clipPath="url(#clip0_2123_10175)">
                <path
                    d="M13.4215 0.808594L6.15231 11.2757L1.32983 7.33856L0.5 8.35554L6.42151 13.19L14.5 1.55722L13.4215 0.808594Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2123_10175">
                    <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </SVG>
    );
};

export default IconOk;
