import { type ReactNode, useEffect, useState } from 'react';
import { type Duration, intervalToDuration } from 'date-fns';

export interface IProps {
    readonly interval?: number,
    readonly date: Date,
    readonly children: (duration: Required<Omit<Duration, 'weeks'>>) => ReactNode
}

export const CountdownDate = ({ interval = 1000, ...props }: IProps) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const id = window.setInterval(() => {
            setCurrentDate(new Date());
        }, interval);

        return () => {
            if(id) {
                window.clearInterval(id);
            }
        };
    }, []);

    const duration = intervalToDuration({
        start: currentDate,
        end  : props.date
    });

    return props.children({
        years  : Number(duration.years) > 0 ? Number(duration.years) : 0,
        months : Number(duration.months) > 0 ? Number(duration.months) : 0,
        days   : Number(duration.days) > 0 ? Number(duration.days) : 0,
        hours  : Number(duration.hours) > 0 ? Number(duration.hours) : 0,
        minutes: Number(duration.minutes) > 0 ? Number(duration.minutes) : 0,
        seconds: Number(duration.seconds) > 0 ? Number(duration.seconds) : 0
    });
};
