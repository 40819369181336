import React from 'react';

import { IPropsSVG, SVG } from '.';

export interface IProps {
    readonly svg?: IPropsSVG
}

export const IconArrowGradient = (props: IProps) => {
    return (
        <SVG
            width="7"
            height="14"
            viewBox="0 0 7 14"
            {...props.svg}
        >
            <path
                d="M3.756 13.036L0.214 6.932L3.742 0.827999H6.528L2.902 6.932L6.528 13.036H3.756Z"
                fill="url(#paint0_linear_4438_5413)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_4438_5413"
                    x1="-0.5"
                    y1="6.5"
                    x2="7.80769"
                    y2="6.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF3838" />
                    <stop
                        offset="1"
                        stopColor="#BD00FF"
                    />
                </linearGradient>
            </defs>
        </SVG>
    );
};

export default IconArrowGradient;
