export default [{ 'inputs': [], 'stateMutability': 'nonpayable', 'type': 'constructor' }, {
    'inputs': [],
    'name'  : 'AccessControlBadConfirmation',
    'type'  : 'error'
}, {
    'inputs': [{ 'internalType': 'address', 'name': 'account', 'type': 'address' }, { 'internalType': 'bytes32', 'name': 'neededRole', 'type': 'bytes32' }],
    'name'  : 'AccessControlUnauthorizedAccount',
    'type'  : 'error'
}, {
    'inputs': [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, { 'internalType': 'uint256', 'name': 'allowance', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : 'needed',
        'type'        : 'uint256'
    }],
    'name': 'ERC20InsufficientAllowance',
    'type': 'error'
}, {
    'inputs': [{ 'internalType': 'address', 'name': 'sender', 'type': 'address' }, { 'internalType': 'uint256', 'name': 'balance', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name'        : 'needed',
        'type'        : 'uint256'
    }],
    'name': 'ERC20InsufficientBalance',
    'type': 'error'
}, { 'inputs': [{ 'internalType': 'address', 'name': 'approver', 'type': 'address' }], 'name': 'ERC20InvalidApprover', 'type': 'error' }, {
    'inputs': [{
        'internalType': 'address',
        'name'        : 'receiver',
        'type'        : 'address'
    }],
    'name': 'ERC20InvalidReceiver',
    'type': 'error'
}, { 'inputs': [{ 'internalType': 'address', 'name': 'sender', 'type': 'address' }], 'name': 'ERC20InvalidSender', 'type': 'error' }, {
    'inputs': [{
        'internalType': 'address',
        'name'        : 'spender',
        'type'        : 'address'
    }],
    'name': 'ERC20InvalidSpender',
    'type': 'error'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
        'indexed'     : true,
        'internalType': 'address',
        'name'        : 'spender',
        'type'        : 'address'
    }, { 'indexed': false, 'internalType': 'uint256', 'name': 'value', 'type': 'uint256' }],
    'name': 'Approval',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'indexed'     : true,
        'internalType': 'bytes32',
        'name'        : 'previousAdminRole',
        'type'        : 'bytes32'
    }, { 'indexed': true, 'internalType': 'bytes32', 'name': 'newAdminRole', 'type': 'bytes32' }],
    'name': 'RoleAdminChanged',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'indexed'     : true,
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }, { 'indexed': true, 'internalType': 'address', 'name': 'sender', 'type': 'address' }],
    'name': 'RoleGranted',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, {
        'indexed'     : true,
        'internalType': 'address',
        'name'        : 'account',
        'type'        : 'address'
    }, { 'indexed': true, 'internalType': 'address', 'name': 'sender', 'type': 'address' }],
    'name': 'RoleRevoked',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs'   : [{ 'indexed': true, 'internalType': 'address', 'name': 'from', 'type': 'address' }, { 'indexed': true, 'internalType': 'address', 'name': 'to', 'type': 'address' }, {
        'indexed'     : false,
        'internalType': 'uint256',
        'name'        : 'value',
        'type'        : 'uint256'
    }],
    'name': 'Transfer',
    'type': 'event'
}, { 'inputs': [], 'name': 'DEFAULT_ADMIN_ROLE', 'outputs': [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }], 'stateMutability': 'view', 'type': 'function' }, {
    'inputs'         : [],
    'name'           : 'MINTER_ROLE',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }, { 'internalType': 'address', 'name': 'spender', 'type': 'address' }],
    'name'           : 'allowance',
    'outputs'        : [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, { 'internalType': 'uint256', 'name': 'value', 'type': 'uint256' }],
    'name'           : 'approve',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'address', 'name': 'account', 'type': 'address' }],
    'name'           : 'balanceOf',
    'outputs'        : [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, { 'inputs': [], 'name': 'decimals', 'outputs': [{ 'internalType': 'uint8', 'name': '', 'type': 'uint8' }], 'stateMutability': 'view', 'type': 'function' }, {
    'inputs': [{
        'internalType': 'bytes32',
        'name'        : 'role',
        'type'        : 'bytes32'
    }],
    'name'           : 'getRoleAdmin',
    'outputs'        : [{ 'internalType': 'bytes32', 'name': '', 'type': 'bytes32' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, { 'internalType': 'address', 'name': 'account', 'type': 'address' }],
    'name'           : 'grantRole',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, { 'internalType': 'address', 'name': 'account', 'type': 'address' }],
    'name'           : 'hasRole',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'address', 'name': 'to', 'type': 'address' }, { 'internalType': 'uint256', 'name': 'amount', 'type': 'uint256' }],
    'name'           : 'mint',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, { 'inputs': [], 'name': 'name', 'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }], 'stateMutability': 'view', 'type': 'function' }, {
    'inputs': [{
        'internalType': 'bytes32',
        'name'        : 'role',
        'type'        : 'bytes32'
    }, { 'internalType': 'address', 'name': 'callerConfirmation', 'type': 'address' }],
    'name'           : 'renounceRole',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bytes32', 'name': 'role', 'type': 'bytes32' }, { 'internalType': 'address', 'name': 'account', 'type': 'address' }],
    'name'           : 'revokeRole',
    'outputs'        : [],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'bytes4', 'name': 'interfaceId', 'type': 'bytes4' }],
    'name'           : 'supportsInterface',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, { 'inputs': [], 'name': 'symbol', 'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }], 'stateMutability': 'view', 'type': 'function' }, {
    'inputs'         : [],
    'name'           : 'totalSupply',
    'outputs'        : [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
    'stateMutability': 'view',
    'type'           : 'function'
}, {
    'inputs'         : [{ 'internalType': 'address', 'name': 'to', 'type': 'address' }, { 'internalType': 'uint256', 'name': 'value', 'type': 'uint256' }],
    'name'           : 'transfer',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}, {
    'inputs': [{ 'internalType': 'address', 'name': 'from', 'type': 'address' }, { 'internalType': 'address', 'name': 'to', 'type': 'address' }, {
        'internalType': 'uint256',
        'name'        : 'value',
        'type'        : 'uint256'
    }],
    'name'           : 'transferFrom',
    'outputs'        : [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
    'stateMutability': 'nonpayable',
    'type'           : 'function'
}] as const;
