import { configureStore, createListenerMiddleware, addListener as addListenerSource, TypedAddListener } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { useSelector as useSelectorRedux, useDispatch as useDispatchRedux, TypedUseSelectorHook } from 'react-redux';
// import { createLogger } from 'redux-logger';

// import { env } from '#helper/env';
import { api as apiDexScreener } from '#adapter/api/dexscreener';
import { api as apiMain } from '#adapter/api/main';

import { slice as sliceMediaQuery } from '#component/media-query/slice';
import { slice as sliceMenu } from '#component/menu/slice';
import { slice as sliceBanner } from '#component/banner/slice';

export const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
    devTools: import.meta.env.DEV,
    reducer : {
        [sliceMediaQuery.name]      : sliceMediaQuery.reducer,
        [apiMain.reducerPath]       : apiMain.reducer,
        [sliceMenu.name]            : sliceMenu.reducer,
        [sliceBanner.name]          : sliceBanner.reducer,
        [apiDexScreener.reducerPath]: apiDexScreener.reducer
    },
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware({
            serializableCheck: false
        }).concat(
            listenerMiddleware.middleware,
            apiDexScreener.middleware,
            apiMain.middleware
        );

        // if(import.meta.env.DEV) {
        //     middleware.push(
        //         createLogger({
        //             duration : env(import.meta.env.APP_REDUX_LOGGER_DURATION),
        //             collapsed: env(import.meta.env.APP_REDUX_LOGGER_COLLAPSED)
        //         })
        //     );
        // }

        return middleware;
    }
});

setupListeners(store.dispatch);

export type TStore = ReturnType<typeof store.getState>;
export type TDispatch = typeof store.dispatch;

export const useDispatch: () => TDispatch = useDispatchRedux;
export const useSelector = useSelectorRedux as TypedUseSelectorHook<TStore>;
export const addListener = addListenerSource as TypedAddListener<TDispatch>;

export default store;
