import { fetchBaseQuery, buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';

import { IParametersPairs, IResponsePairs, IResponseTokens, IParametersTokens, IResponseSearch, IParametersSearch } from './types';

export const createApi = buildCreateApi(coreModule(), reactHooksModule());

export const api = createApi({
    reducerPath: 'api/dexscreener',
    baseQuery  : fetchBaseQuery({
        baseUrl: 'https://api.dexscreener.com/latest/dex',
        headers: { 'cache-control': 'no-cache' }
    }),
    tagTypes : [],
    endpoints: (build) => ({
        pairs: build.query<IResponsePairs, IParametersPairs>({
            query: (params) => ({
                method: 'GET',
                url   : `/pairs/${params.path.chainId}/${params.path.pairAddresses.join(',')}`
            })
        }),
        tokens: build.query<IResponseTokens, IParametersTokens>({
            query: (params) => ({
                method: 'GET',
                url   : `/tokens/${params.path.tokenAddreses.join(',')}`
            })
        }),
        search: build.query<IResponseSearch, IParametersSearch>({
            query: (params) => ({
                method: 'GET',
                url   : '/search',
                params: {
                    q: params.params.q
                }
            })
        })
    })
});
