import React, { ReactNode, useMemo } from 'react';

import { type TStyle, useClassnames } from '#hook/use-classnames';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string,
    readonly children?: ReactNode,
    readonly header?: ReactNode,
    readonly text?: ReactNode
}

export const ContentBox = (props: IProps) => {
    const cn = useClassnames(style, props.className);

    const elHeader = useMemo(() => {
        if(props.header) {
            return (
                <h2
                    className={cn('content-box__header')}
                    children={props.header}
                />
            );
        }
    }, [cn, props.header]);

    const elText = useMemo(() => {
        if(props.text) {
            return (
                <p className={cn('content-box__text')}>
                    {props.text}
                </p>
            );
        }
    }, [cn, props.text]);

    return (
        <article className={cn('content-box')}>
            {elHeader}
            {elText}
            {props.children}
        </article>
    );
};
