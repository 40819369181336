import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useClassnames, TStyle } from '#hook/use-classnames';
import { usdNumberFormat } from '#helper/usd-number-format';
import { useSelector } from '#core/store';
import api from '#adapter/api/main';

import { slice as sliceMediaQuery } from '#component/media-query/slice';

import style from './index.module.pcss';

interface IProps {
    className?: string | TStyle
}

export const MarketcapOverview = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation(undefined, {
        keyPrefix: 'components.marketcap-overview'
    });
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);
    const { data: playWeb3Tokens, isLoading: isPlayWeb3TokensLoading, error: errorPlayWeb3Tokens } = api.endpoints.apiV1StatisticsPlayWeb3GameTokensGet.useQuery();

    if(isPlayWeb3TokensLoading || errorPlayWeb3Tokens) {
        return null;
    }

    return (
        <Link to="/playweb3-stats">
            <div className={cn('marketcap-overview')}>
                <h2 className={cn('marketcap-overview__title')}>{t('title')}</h2>
                <div className={cn('marketcap-overview__values')}>
                    <div className={cn('marketcap-overview__value')}>
                        <p>
                            {t('marketcap', {
                                context: isDesktop ? 'desktop' : 'mobile'
                            })}
                        </p>
                        <strong>{usdNumberFormat(playWeb3Tokens?.total_market_cap || 0, { notation: !isDesktop ? 'compact' : 'standard' })}</strong>
                        {playWeb3Tokens?.total_market_cap_change !== 0 && playWeb3Tokens?.total_market_cap_change && (
                            <span
                                className={cn('marketcap-overview__block-diff', {
                                    'marketcap-overview__block-diff_red'  : playWeb3Tokens?.total_market_cap_change < 0,
                                    'marketcap-overview__block-diff_green': playWeb3Tokens?.total_market_cap_change > 0
                                })}
                            >
                                {playWeb3Tokens?.total_market_cap_change.toFixed(2)}
                                %
                            </span>
                        )}
                    </div>
                    <div className={cn('marketcap-overview__value')}>
                        <p>
                            {t('volume', {
                                context: isDesktop ? 'desktop' : 'mobile'
                            })}
                        </p>
                        <strong>{usdNumberFormat(Math.trunc(playWeb3Tokens?.volume || 0), { notation: !isDesktop ? 'compact' : 'standard' })}</strong>
                        {playWeb3Tokens?.volume_change !== 0 && playWeb3Tokens?.volume_change && (
                            <span
                                className={cn('marketcap-overview__block-diff', {
                                    'marketcap-overview__block-diff_red'  : playWeb3Tokens?.volume_change < 0,
                                    'marketcap-overview__block-diff_green': playWeb3Tokens?.volume_change > 0
                                })}
                            >
                                {playWeb3Tokens?.volume_change.toFixed(2)}
                                %
                            </span>
                        )}

                    </div>
                </div>
            </div>
        </Link>
    );
};
