import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAccountEffect } from 'wagmi';

import { useDidUpdateEffect } from '#hook/use-did-update-effect';
import api from '#adapter/api/main';

import { QUERY_PARAMS_REF_KEY } from '..';

const LS_REF_KEY = 'referral-key';
const RW_REF_KEY = 'register-wellcome';

export const useAffilateLink = () => {
    const [params, setSearchParams] = useSearchParams();
    const queryKey = params.get(QUERY_PARAMS_REF_KEY);
    const lsKey = window.localStorage.getItem(LS_REF_KEY);
    const { data: userInfo } = api.endpoints.apiV1UsersWhoamiGet.useQuery();
    const [registerAffilate] = api.endpoints.apiV1AffiliateRegisterAffiliatePost.useMutation();
    const [registerWellcomeAffilate, { isSuccess }] = api.endpoints.apiV1AffiliateWelcomeAffiliatePost.useMutation();

    const isRegistered = window.localStorage.getItem(RW_REF_KEY) === 'true';

    useDidUpdateEffect(() => {
        if(queryKey && !isRegistered) {
            void registerWellcomeAffilate({
                body: {
                    link_key: (queryKey || lsKey) as string
                }
            });

            if(queryKey !== lsKey) {
                window.localStorage.setItem(LS_REF_KEY, queryKey);
            }
        }
    }, [isRegistered, queryKey]);

    // Обрабатываем успех выполнения запроса
    useEffect(() => {
        if(isSuccess && !isRegistered) {
            window.localStorage.setItem(RW_REF_KEY, 'true');
        }
    }, [isSuccess, isRegistered]);

    useAccountEffect({
        onConnect: (wallerData) => {
            if((queryKey || lsKey) && userInfo?.affiliate_links?.[0]?.link_key !== lsKey && !userInfo?.registered_by_affiliate) {
                void registerAffilate({
                    body: {
                        new_wallet: wallerData.address,
                        link_key  : (queryKey || lsKey) as string
                    }
                })
                    .unwrap()
                    .then(() => {
                        localStorage.removeItem(LS_REF_KEY);
                        setSearchParams({});
                    });
            }
        }
    });
};
