import React, { type ElementType, createElement } from 'react';

import { type TStyle, useClassnames } from '#hook/use-classnames';

import style from './index.module.pcss';

export interface IProps<T extends ElementType> {
    readonly as?: T,
    readonly className?: TStyle | string,
    readonly presetSize?: 'very-large' | 'large' | 'default' | 'small',
    readonly presetStyle?: `${'primary' | 'secondary'}-${'violet' | 'orange'}`,
    readonly uppercase?: boolean
}

export const ButtonSimple = <T extends ElementType = 'button'>({
    as,
    presetSize = 'default',
    presetStyle = 'primary-violet',
    className,
    uppercase,
    ...props
}: IProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof IProps<T>>) => {
    const cn = useClassnames(style, className);

    return createElement(as || 'button', {
        ...props,
        className: cn('button-simple', {
            [`button-simple_size-${presetSize}`]  : presetSize,
            [`button-simple_style-${presetStyle}`]: presetStyle,
            'button-simple_uppercase'             : uppercase
        })
    });
};
