import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TMediaKey = 'isMobile' | 'isDesktop';

type TMediaQuery = Record<TMediaKey, boolean>;

type TState = TMediaQuery;

export const MEDIA_MATCHES = {
    isDesktop: window.matchMedia('(min-width: 850px)')
};

const initialState: TState = {
    isMobile : !MEDIA_MATCHES.isDesktop.matches,
    isDesktop: MEDIA_MATCHES.isDesktop.matches
};

export const slice = createSlice({
    initialState,
    name    : 'media-query',
    reducers: {
        reset: () => initialState,
        set  : (state, action: PayloadAction<Partial<TState>>) => {
            return {
                ...state,
                ...action.payload
            };
        }
    }
});
