import React, { Suspense, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useClassnames, type TStyle } from '#hook/use-classnames';

import { Header } from '#component/header';
import { Footer } from '#component/footer';
// import { HeaderMarquee } from '#component/header-marquee';
// import { GlobalNotification } from '#component/global-notification';
// import { Button } from '#component/button';
import { CookieAgreement } from '#component/cookie-agreement';
import { useAffilateLink } from '#component/referral/hooks/use-affilate-link';
// import { EventBanner } from '#component/banner/marketing/event';
import { IgoBanner } from '#component/banner/marketing/igo';

import style from './index.module.pcss';

export interface IProps {
    className?: TStyle | string
}

export const Layout = (props: IProps) => {
    useAffilateLink();
    const cn = useClassnames(style, props.className);
    const location = useLocation();

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Suspense fallback={null}>
            <div className={cn('layout-sticky')}>
                {/* <HeaderMarquee /> */}
                {/* <GlobalNotification className={cn('layout__notification')}>
                    <div className={cn('layout__notification-content')}>
                        <p>Alpha version. Stay tuned for our updates!&nbsp; 🚀</p>
                        <Button
                            presetStyle="white"
                            className={cn('layout__notification-button')}
                            href="https://t.me/playweb3_io"
                        >
                            Learn more
                        </Button>
                    </div>
                </GlobalNotification> */}
                <Header />
            </div>
            <div className={cn('layout')}>
                <main>
                    <Outlet />
                </main>
                <CookieAgreement />
                {/*<EventBanner />*/}
                <IgoBanner />
                <Footer />
            </div>
        </Suspense>
    );
};
