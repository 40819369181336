import React, { ButtonHTMLAttributes, HtmlHTMLAttributes, AnchorHTMLAttributes, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { type TStyle, useClassnames } from '#hook/use-classnames';

import style from './index.module.pcss';

type TButtonElement = ButtonHTMLAttributes<HTMLButtonElement>;
type THtmlElement = HtmlHTMLAttributes<HTMLElement>;
type TAnchorElement = AnchorHTMLAttributes<HTMLAnchorElement>;

export interface IProps {
    readonly form?: TButtonElement['form'],
    readonly className?: TStyle | string,
    readonly presetSize?: 'default' | 'small' | 'extra-small' | 'large',
    readonly presetStyle?: 'primary-violet' | 'primary-orange' | 'secondary' | 'secondary-violet' | 'secondary-orange' | 'circle-violet' | 'white',
    readonly id?: THtmlElement['id'],
    readonly type?: TButtonElement['type'],
    readonly tabIndex?: THtmlElement['tabIndex'],
    readonly onFocus?: THtmlElement['onFocus'],
    readonly onClick?: THtmlElement['onClick'],
    readonly children?: THtmlElement['children'],
    readonly accessKey?: THtmlElement['accessKey'],
    readonly uppercase?: boolean,
    readonly disabled?: boolean,
    readonly href?: TAnchorElement['href'],
    readonly target?: TAnchorElement['target'],
    readonly to?: LinkProps['to'],
    readonly isHoverDisabled?: boolean
}

export const Button = forwardRef<any, IProps>(({ presetSize = 'default', presetStyle = 'primary-violet', type = 'button', ...props }, ref) => {
    const cn = useClassnames(style, props.className);

    const commonProps = {
        ref,
        id       : props.id,
        tabIndex : props.tabIndex,
        onFocus  : props.onFocus,
        onClick  : props.onClick,
        children : props.children,
        accessKey: props.accessKey,
        className: cn('button', {
            [`button_${presetSize}`] : presetSize,
            [`button_${presetStyle}`]: presetStyle,
            'button_uppercase'       : props.uppercase,
            'button_no-hover'        : props.isHoverDisabled
        })
    };

    if(props.to) {
        return (
            <Link
                to={props.to}
                target={props.target}
                {...commonProps}
            />
        );
    }

    if(props.href) {
        return (
            <a
                href={props.href}
                target={props.target}
                {...commonProps}
            >
                {props.children}
            </a>
        );
    }

    return (
        <button
            type={type}
            form={props.form}
            disabled={props.disabled}
            {...commonProps}
        />
    );
});
