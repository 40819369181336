import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassnames } from '#hook/use-classnames';
import { useSelector } from '#core/store';

import { slice as sliceMediaQuery } from '#component/media-query/slice';
import { CopyButton } from '#component/copy-button';

import style from './index.module.pcss';

interface IProps {
    title: string,
    text: string,
    count: number
}

type TPresetStyle = 'rookie' | 'buddy' | 'warrior' | 'master';

export const UserStatus = (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);
    const [presetStyle, setPresetStyle] = useState<TPresetStyle>('rookie');

    useEffect(() => {
        let newStyle = presetStyle;

        if(props.count > 0 && props.count < 10) {
            newStyle = 'rookie';
        } else if(props.count >= 10 && props.count < 50) {
            newStyle = 'buddy';
        } else if(props.count >= 50 && props.count < 100) {
            newStyle = 'warrior';
        } else if(props.count >= 100) {
            newStyle = 'master';
        }

        setPresetStyle(newStyle);
    }, [props.count]);

    return (
        <div
            className={cn('user-status', `user-status_${presetStyle}`)}
        >
            <div className={cn('user-status__content')}>
                <h4 className={cn('user-status__content-title')}>{t('components.referral.status.achieve')}</h4>
                <div className={cn('user-status__text-block')}>
                    <h3 className={cn('user-status__text-title')}>{props.title}</h3>
                    <p>{props.text}</p>
                </div>
                <CopyButton
                    copiedText={t('components.referral.ref-block.copied')}
                    linkToCopy="/"
                    button={{
                        presetSize: isDesktop ? 'small' : 'default',
                        className : cn('user-status__button'),
                        uppercase : true,
                        children  : t('components.referral.status.button')
                    }}
                />
            </div>
        </div>
    );
};
