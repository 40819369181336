
export const formatter = (options?: Intl.NumberFormatOptions) => new Intl.NumberFormat('en-US', {
    style                : 'currency',
    currency             : 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...(options || {})
});

export const numberFormatWithDecimals = (value: number, maxDecimals: number, options?: Intl.NumberFormatOptions) => {
    return formatter({
        minimumFractionDigits: 0,
        maximumFractionDigits: maxDecimals,
        currency             : undefined,
        style                : undefined,
        ...options
    }).format(value);
};

export const usdNumberFormat = (value: number, options?: Intl.NumberFormatOptions) => {
    return formatter(options)
        .format(value)
        .replace(/[A-Z]/, (match) => { // Ставим пробел перед буквой компакт мода (T, K, M, B...)
            return `\u00A0${match}`;
        });
};
