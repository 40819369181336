import React, { useMemo } from 'react';

import { useClassnames } from '#hook/use-classnames';

import statisticsIconsMap from '#component/icons/statistics/map';

import style from './index.module.pcss';

export type TStatsIconMap = keyof typeof statisticsIconsMap;

export interface IProps {
    title: string,
    content: string | number,
    icon: TStatsIconMap
}

export const StatCard = (props: IProps) => {
    const cn = useClassnames(style);

    const elIcon = useMemo(() => {
        return React.createElement(statisticsIconsMap[props.icon], {
            svg: {
                className: cn('stat-card__icon')
            }
        });
    }, [props.icon]);

    return (
        <div className={cn('stat-card')}>
            <h4 className={cn('stat-card__title')}>
                {props.title}
            </h4>
            <div className={cn('stat-card__content')}>
                <p className={cn('stat-card__text')}>{props.content}</p>
                {elIcon}
            </div>
        </div>
    );
};
