import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayer, Arrow } from 'react-laag';

import { type TStyle, useClassnames } from '#hook/use-classnames';

import { SUPPORTED_LNGS } from '#locale';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string,
    readonly preset?: 'header' | 'footer'
}

export const LangControls = ({ preset = 'header', ...props }: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t, i18n } = useTranslation();
    const [isOpen, setOpen] = useState(false);

    const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
        isOpen,
        overflowContainer: true,
        placement        : preset === 'header' ? 'bottom-center' : 'top-center',
        auto             : true,
        triggerOffset    : 7,
        arrowOffset      : 0,
        onDisappear      : (disappearType) => {
            if(disappearType === 'full') {
                setOpen(false);
            }
        },
        onOutsideClick: () => setOpen(false)
    });

    const elLanguages = useMemo(() => {
        if(isOpen) {
            return renderLayer(
                <div
                    {...layerProps}
                    className={cn('lang-controls-window')}
                >
                    <Arrow
                        {...arrowProps}
                        style={{
                            ...arrowProps.style,
                            [arrowProps.layerSide]: arrowProps.layerSide !== 'center' && arrowProps.style[arrowProps.layerSide] && `calc(${arrowProps.style[arrowProps.layerSide]} - 1px)`
                        }}
                        size={6}
                        borderWidth={1}
                        borderColor="#4500ca"
                        backgroundColor="#000"
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    />
                    <ul
                        className={cn('lang-controls__list')}
                        data-preset={preset}
                    >
                        {SUPPORTED_LNGS.filter((lang) => lang !== i18n.language).map((lang) => (
                            <li
                                key={lang}
                                children={preset === 'header' ? lang : t(`components.lang-controls.name.${lang}`)}
                                className={cn('lang-controls__list-item')}
                                onClick={() => {
                                    if(lang !== i18n.language) {
                                        i18n
                                            .changeLanguage(lang)
                                            .catch(console.error);

                                        setOpen(false);
                                    }
                                }}
                            />
                        ))}
                    </ul>
                </div>
            );
        }
    }, [t, cn, renderLayer, isOpen, preset, layerProps, arrowProps, i18n]);

    const elIconWorld = useMemo(() => {
        if(preset === 'footer') {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.128 6.084a6.238 6.238 0 0 1 5.768-2.27l.531 1.063L9.393 6.91l-2.188.312-1.072 1.072-1.005-2.21Zm-.794 1.274a6.252 6.252 0 0 0 4.572 8.797l.343-.555-1.903-2.644 1.3-1.685-.619-.309-2.243.374-.44-1.755-1.01-2.223ZM6.63 9.567l.086.347 1.507-.251 1.254.627 2.258-.314 4.06 2.369a6.251 6.251 0 0 0-3.268-8.062l.421.84L9.982 8.09l-2.187.313L6.63 9.567Zm8.582 3.884a6.245 6.245 0 0 1-4.89 2.79l.429-.695-1.847-2.564 1.167-1.513 1.408-.195 3.733 2.177ZM10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Z"
                        clipRule="evenodd"
                    />
                </svg>
            );
        }
    }, [preset]);

    return (
        <Fragment>
            <div
                {...triggerProps}
                role="button"
                tabIndex={0}
                className={cn('lang-controls', `lang-controls_preset-${preset}`)}
                onClick={() => {
                    setOpen(!isOpen);
                }}
            >
                {elIconWorld}
                {preset === 'footer' ? t('components.lang-controls.language') : i18n.language}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    className={cn('lang-controls__icon-arrow', {
                        'lang-controls__icon-arrow_is-open': isOpen
                    })}
                >
                    <path d="M4.472 7.031a.747.747 0 0 0 1.058 0l2.99-3a.748.748 0 0 0 .162-.818.748.748 0 0 0-.691-.464l-5.982.002a.75.75 0 0 0-.53 1.282l2.99 3 .003-.002z" />
                </svg>
            </div>
            {elLanguages}
        </Fragment>
    );
};
