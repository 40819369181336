import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';

import { TStyle, useClassnames } from '#hook/use-classnames';
import { padStart } from '#helper/pad-start';

import { CountdownDate } from '#component/countdown-date';

import { STATUS_MAP } from '#const';

import style from './index.module.pcss';

export interface IProps {
    readonly status?: typeof STATUS_MAP[keyof typeof STATUS_MAP],
    readonly date?: Date | null,
    readonly className?: TStyle | string,
    readonly tokenName?: string | null
}

const KEYS = ['days', 'hours', 'minutes', 'seconds'] as const;

export const Countdown = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation('routes/launchpad');

    const isInvalidDate = !props.date || isBefore(props.date, new Date());
    const isExcludedStatus = ['presale', 'active', 'refund'].includes(props.status || '');

    if(isInvalidDate || !props.status || isExcludedStatus) {
        return null;
    }

    return (
        <CountdownDate
            date={props.date}
            children={(duration) => (
                <div
                    data-label={props.status === 'completed' && !!props.tokenName ? `${props.tokenName} Listing in` : undefined}
                    className={cn('countdown', {
                        'countdown_white': props.status === 'completed' && !!props.date
                    })}
                >
                    {KEYS.map((key, index) => (
                        <Fragment key={key}>
                            {!!index && ':'}
                            <span
                                data-label={t(`components.countdown.${key}`)}
                                children={padStart(duration[key])}
                            />
                        </Fragment>
                    ))}
                </div>
            )}
        />
    );
};
